<template>
  <v-menu
    v-model="datesMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    class="overlay"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="fieldText"
        :value="dateRangeText"
        :hint="dateHintText"
        @click:append="datesMenu = !datesMenu"
        append-icon="mdi-calendar"
        persistent-hint
        readonly
        outlined
        dense
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-card>
      <v-date-picker
        v-model="dates"
        locale="es-ES"
        color="primary"
        width="100%"
        selected-items-text="Rango de fechas"
        block
        range
      >
      </v-date-picker>
      <v-divider></v-divider>
      <div v-if="!hideFooter" class="d-sm-flex" style="width: 100%">
        <v-btn
          @click="datesMenu = false"
          class="ma-3"
          color="primary"
          :disabled="dates.length === 0"
          depressed
        >
          <v-icon class="mr-1">mdi-magnify</v-icon>
          buscar</v-btn
        >
        <v-btn @click="cancel()" class="ma-3" depressed> cancelar</v-btn>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: "RangeDateFilter",
  props: ["label", "labelRange", "hideFooter"],
  data() {
    return {
      dates: [moment().startOf("day").toDate().toJSON().slice(0, 10)],
      datesApplied: [moment().startOf("day").toDate().toJSON().slice(0, 10)],
      datesMenu: false,
      canceling: false,
    };
  },
  computed: {
    dateRangeText() {
      return this.dates
        .map((date) => moment(date).format("DD/MM/YYYY"))
        .join(" ~ ");
    },
    dateHintText() {
      let prefix = "";
      if (
        this.dates.length == 1 &&
        this.dates[0] == moment().format("YYYY-MM-DD")
      ) {
        prefix = "hoy, ";
      }

      return (
        prefix +
        this.dates
          .map((date) => {
            const dateFormatted = moment(date).locale("es").format("LLLL");
            return dateFormatted.substring(0, dateFormatted.length - 4);
          })
          .join(" ~ ")
      );
    },
    fieldText() {
      const label = this.label || "Fecha";
      const labelRange = this.labelRange || "Fechas";
      return this.dates.length > 1 ? labelRange : label;
    },
  },
  methods: {
    applyFilter() {
      this.datesApplied = this.dates;

      const from = this.dates[0];
      const to = this.dates[1] || this.dates[0];
      this.$emit("filter", {
        from,
        to,
      });

      this.datesMenu = false;
    },
    cancel() {
      this.canceling = true;
      this.datesMenu = false;

      // use dates old value selected when calendar menu is closed
      this.dates =
        this.datesApplied.length > 0
          ? this.datesApplied
          : [moment().startOf("day").toDate().toJSON().slice(0, 10)];
    },
  },
  watch: {
    datesMenu(open) {
      // clear dates selected when open the calendar menu
      if (open) {
        this.dates = [];
      }

      // click buscar
      if (!open && !this.canceling) {
        this.applyFilter();
      }

      // click outside dialog
      if (!open && this.canceling) {
        this.canceling = false;
      }
    },
  },
};
</script>
