<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="goBack"
                color="secondary"
                text
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span class="caption">Atrás</span>
          </v-tooltip>
          <h2 class="secondary--text" v-if="doctor && doctor.user">
            {{ doctor.user.firstName }} {{ doctor.user.lastName }}
          </h2>
        </div>

        <v-divider class="mt-5"></v-divider>
        <div class="mb-8 d-flex">
          <v-tabs
            v-model="tab"
            icons-and-text
            height="40"
            color="secondary"
            slider-color="secondary"
          >
            <v-tab v-for="tabItem in tabs" :key="tabItem.label">
              <div class="caption font-weight-medium d-flex align-center">
                <div>{{ tabItem.label }}</div>
              </div>
            </v-tab>
          </v-tabs>
        </div>

        <div v-if="tab == 0">
          <div class="d-md-flex align-start justify-space-between">
            <div class="d-md-flex align-center">
              <div class="mr-3">
                <RangeDateFilter
                  @filter="handleFilterByDate"
                  label="Fecha"
                  labelRange="Fecha"
                />
              </div>
              <div class="d-flex align-center mb-6">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      text
                      icon
                      title="Refrescar datos"
                      @click="getData"
                      :disabled="loading"
                    >
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Refrescar datos</span>
                </v-tooltip>
              </div>
            </div>
          </div>

          <v-slide-x-reverse-transition mode="out-in">
            <div v-if="!loading" class="mt-5">
              <v-row>
                <v-col cols="12" md="4">
                  <v-card hover rounded="lg" class="pa-5" :elevation="'6'">
                    <InfoItem
                      label="Número de consultas atendidas"
                      :icon="'mdi-check-bold'"
                      :iconBg="'primary'"
                      :iconColor="'white'"
                      :val="stats.completed"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card hover rounded="lg" class="pa-5" :elevation="'6'">
                    <InfoItem
                      label="Número de consultas pendiente de atender"
                      :icon="'mdi-account-clock'"
                      :iconColor="'secondary'"
                      :val="stats.pending"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card hover rounded="lg" class="pa-5" :elevation="'6'">
                    <InfoItem
                      label="Número de consultas cancelada"
                      :icon="'mdi-close-thick'"
                      :iconBg="'error'"
                      :iconColor="'white'"
                      :val="stats.deleted"
                    />
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card hover rounded="lg" class="pa-5" :elevation="'6'">
                    <InfoItem
                      label="Número de pacientes"
                      :icon="'mdi-human-handsdown'"
                      :iconBg="'success'"
                      :iconColor="'white'"
                      :val="stats.patients"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-slide-x-reverse-transition>
        </div>

        <div v-if="tab == 1">
          <v-timeline dense>
            <v-slide-x-reverse-transition group hide-on-leave>
              <v-timeline-item
                v-for="activity in activities"
                :key="activity.id"
                :color="getActivityContent(activity).color"
                small
              >
                <v-alert
                  :value="true"
                  :color="getActivityContent(activity).color"
                  :icon="getActivityContent(activity).icon"
                  colored-border
                  elevation="5"
                  border="left"
                >
                  <div class="d-md-flex justify-space-between">
                    <div>
                      <div class="subtitle-1">
                        {{ activity.message }}
                      </div>
                      <div v-if="activity.user" class="caption">
                        {{ activity.user.firstName }}
                        {{ activity.user.lastName }}
                      </div>
                      <div v-else>Usuario Indefinido</div>
                    </div>
                    <div class="caption">
                      {{ formatDate(activity.createdAt) }}
                    </div>
                  </div>
                </v-alert>
              </v-timeline-item>
            </v-slide-x-reverse-transition>
          </v-timeline>

          <div class="d-flex justify-center">
            <v-btn
              v-if="!allActivitiesLoaded && !loading"
              color="primary"
              @click="loadMoreActivities"
              depressed
              >Cargar más</v-btn
            >
          </div>
        </div>

        <div v-if="loading" class="d-flex justify-center my-10">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import RangeDateFilter from "../components/RangeDateFilter.vue";
import InfoItem from "../components/InfoItem.vue";

export default {
  name: "Doctor",
  components: {
    RangeDateFilter,
    InfoItem,
  },
  data() {
    return {
      from: "",
      to: "",
      loading: true,
      doctor: null,
      stats: {
        completed: 0,
        pending: 0,
        deleted: 0,
        patients: 0,
      },

      activities: [],
      activitiesPage: 1,
      activitiesPageSize: 15,
      allActivitiesLoaded: false,

      tab: 0,
      tabs: [
        {
          value: 0,
          label: "Estadisticas",
          dataFetched: false,
        },
        {
          value: 1,
          label: "Actividad",
          dataFetched: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    ...mapActions(["fetchDoctorStats", "fetchUserActivity"]),
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    goBack() {
      this.$router.go(-1);
    },
    getData() {
      this.dataFetched();

      if (this.tab === 0) {
        this.getStats();
      }

      if (this.tab === 1) {
        this.getActivity();
      }
    },

    handleFilterByDate({ from, to }) {
      this.from = from || this.todayDate;
      this.to = to || this.todayDate;
      this.getData();
    },
    async getStats() {
      this.loading = true;

      const data = await this.fetchDoctorStats({
        doctorId: this.$route.params.id,
        dateFrom: this.from,
        dateTo: this.to,
      });

      if (!data) {
        this.loading = false;
        return false;
      }

      const { stats, doctor } = data;
      this.stats = {
        completed: stats.appointmentsCompleted,
        deleted: stats.appointmentsDeleted,
        pending: stats.appointmentsPending,
        patients: stats.patientsCount,
      };

      this.doctor = doctor;
      this.loading = false;
    },
    getActivityContent({ action, isError }) {
      if (isError) {
        return {
          color: "error",
          icon: "mdi-error-outline",
        };
      }

      if (action == 1) {
        return {
          color: "success",
          icon: "mdi-check-circle-outline",
        };
      }

      if (action == 2 || action == 5) {
        return {
          color: "#3F51B5",
          icon: "mdi-check-network-outline",
        };
      }

      if (action == 3) {
        return {
          color: "primary",
          icon: "mdi-information-slab-circle-outline",
        };
      }

      if (action == 4) {
        return {
          color: "#263238",
          icon: "mdi-delete-circle-outline",
        };
      }
    },
    async getActivity() {
      this.loading = true;

      const data = await this.fetchUserActivity({
        doctorId: this.$route.params.id,
        page: this.activitiesPage,
        pageSize: this.activitiesPageSize,
      });

      if (!data) {
        this.loading = false;
        return false;
      }

      const items = data?.items || [];
      this.activities = [...this.activities, ...items];

      if (items.length < this.activitiesPageSize) {
        this.allActivitiesLoaded = true;
      }

      this.loading = false;
    },
    loadMoreActivities() {
      if (!this.allActivitiesLoaded && !this.loading) {
        this.activitiesPage += 1;
        this.getActivity();
      }
    },
    dataFetched() {
      this.tabs = this.tabs.map((tab) => {
        if (this.tab === tab.value) {
          return {
            ...tab,
            dataFetched: true,
          };
        }
        return tab;
      });
    },
  },
  watch: {
    tab: function (value) {
      const tab = this.tabs.filter((tab) => tab.value === value)[0];
      if (!tab.dataFetched) {
        this.getData();
      }
    },
  },
  mounted() {
    this.from = moment().startOf("day").toDate().toJSON().slice(0, 10);
    this.to = moment().startOf("day").toDate().toJSON().slice(0, 10);
    this.getData();
  },
};
</script>
